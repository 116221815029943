
import Vue from 'vue';
import router from '@/core/router';
import Component from 'vue-class-component';
import { showAlert } from '@/core/plugins/Notifications';
import { http } from '@/core/http/HttpFetch';
import LanguageSwitcher from '../LanguageSwitcher.vue';

@Component({
  name: 'ForgetPassword',
  components: {
    LanguageSwitcher
  }
})
export default class AppForgetPassword extends Vue {
  //Data
  forgetEmail = '';
  forgetPassword = '';
  forgetRePassword = '';
  disabledForm = true;
  isLoading = false;
  code = '';
  //Refs
  $refs!: {
    forgetEmail: HTMLInputElement;
  };
  //Hook
  created() {
    this.$nextTick(() => this.$refs.forgetEmail.focus());
  }
  //Methods
  public getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
  async getSecurityCodeCustomer(email: string) {
    const response = await http.noTokenPost(
      process.env.VUE_APP_URL_TOKEN_AUTH_COMPANY + '/user/recoverPassword',
      email
    );

    if (response) {
      this.disabledForm = false;
      showAlert('success', 'Se ha enviado el código de seguridad a su correo electrónico');
    } else {
      showAlert('error', 'No se ha podido enviar el código de seguridad a su correo electrónico');
    }
  }
  async updatePasswordUser() {
    this.isLoading = true;
    const payload = {
      password: this.forgetPassword,
      code: this.code,
      email: this.forgetEmail
    };

    try {
      const response: boolean | unknown = await http.noTokenPut(
        process.env.VUE_APP_URL_TOKEN_AUTH_COMPANY + '/user/verifyCode',
        JSON.stringify(payload)
      );

      if (response === true) {
        showAlert('success', 'Se ha actualizado la contraseña correctamente');
        router.push({ name: 'Login' });
      } else {
        showAlert('error', 'No se ha podido actualizar la contraseña');
      }
    } catch (error) {
      showAlert('error', 'No se ha podido actualizar la contraseña');
    } finally {
      this.isLoading = false;
    }
  }
}
